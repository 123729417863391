import ReactApexChart from "react-apexcharts";
// material
import { Card, CardHeader, Box } from "@mui/material";
//
// import { BaseOptionChart } from "../../charts";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";


// ----------------------------------------------------------------------

// const CHART_DATA = [
//   {
//     name: "Earning",
//     type: "column",
//     data: [0, 10000, 20000, 30000, 40000],
//   },
// ];

// export default function AppWebsiteVisits() {

//   const creators = useSelector((state) => state.creator.data);
//   console.log('creators are', creators)

//   useEffect(() => {
//     let totalStars = creators.reduce((sum, currentObj) => sum + currentObj.stars, 0);
//     console.log('total tokens are', totalStars )

//   }, [])
//   const { t } = useTranslation();
//   return (
//     <Card>
//       <CardHeader
//         title={`${t("Earnings_Overview")}`}
//         subheader="(+43%) than last year"
//       />
//       <Box sx={{ p: 3, pb: 1 }} dir="ltr">
//         <ReactApexChart
//           type="line"
//           series={CHART_DATA}
//           // options={chartOptions}
//           options={{
//             // labels: ["Jan", "Mar", "May", "Jul", "Sep", "Nov"],
//             stroke: {
//               curve: "smooth",
//             },
//             colors: ["#4e73df"],
//             xaxis: {
//               categories: [
//                 "Jan",
//                 "Feb",
//                 "Mar",
//                 "Apr",
//                 "May",
//                 "Jun",
//                 "Jul",
//                 "Aug",
//                 "Sep",
//                 "Oct",
//                 "Nov",
//                 "Dec",
//               ],
//             },
//             // plotOptions: { bar: { columnWidth: "50%", borderRadius: 25 } },
//             dataLabels: {
//               formatter: (val) => {
//                 return `$${val}`;
//               },
//             },
//             yaxis: {
//               labels: {
//                 formatter: (val) => {
//                   return `$${val}`;
//                 },
//               },
//             },
//           }}
//           height={364}
//           // width={100}
//         />
//       </Box>
//     </Card>
//   );
// }

//... other imports

export default function AppWebsiteVisits() {
  const creators = useSelector((state) => state.creator.data);
  const [totalStars, setTotalStars] = useState(0); // Initialize totalStars state

  useEffect(() => {
    // let totalstar = creators.reduce((sum, currentObj) => sum + Number(currentObj.stars), 0);
    let total = creators.reduce((sum, currentObj) => {
      let dollarValue = currentObj.stars / 2000; // 2000 tokens is $1
      let revenue = dollarValue * 0.3; // You get 30% of total revenue
      return sum + revenue;
  }, 0);
  setTotalStars(total); // Update totalStars state
  }, [creators]) // Add creators as a dependency to recalculate when it changes

  const chartData = [
    {
      name: "Earning",
      type: "column",
      data: [0, totalStars/4, totalStars/2, totalStars/4 * 3, totalStars],
    },
  ];

  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader
        title={`${t("Earnings_Overview")}`}
        subheader="(+43%) than last year"
      />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          type="line"
          series={chartData}
          // options={chartOptions}
          options={{
            // labels: ["Jan", "Mar", "May", "Jul", "Sep", "Nov"],
            stroke: {
              curve: "smooth",
            },
            colors: ["#4e73df"],
            xaxis: {
              categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ],
            },
            // plotOptions: { bar: { columnWidth: "50%", borderRadius: 25 } },
            dataLabels: {
              formatter: (val) => {
                return `$${val}`;
              },
            },
            yaxis: {
              labels: {
                formatter: (val) => {
                  return `$${val}`;
                },
              },
            },
          }}
          height={364}
          // width={100}
        />
      </Box>
    </Card>
  );
}
