// material
import React from "react";
import { Box, Grid, Container, Typography } from "@mui/material";

// components
import Page from "../components/Page";
import {
  AppTasks,
  TotalRegisterCreator,
  TotalReports,
  AppNewsUpdate,
  TotalRegisterUsers,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates,
} from "../components/_dashboard/app";
import { useTranslation } from "react-i18next";
import {useEffect} from 'react'
import {messaging} from '../database/db'
import { getToken } from "firebase/messaging";

// --------------------------------------------------

import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import { getMessaging, onMessage } from "firebase/messaging";

// ----------------------------------------------------------------------

export default function DashboardApp() {



  useEffect(() => {
    
async function requestPermission() {
  console.log('Requesting permission...');
  await Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      getToken(messaging, {
        vapidKey:
          "BEM2tCAaEoleoPBS-JPBb5VkU3H7RHXcOSKAvWDFHuWrymhcow1pEIVwBw0XR4wawQwGscOf4ewgugdI472g4zk",
      })
        .then((currentToken) => {
          
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err.toString());
        });
    }
    else {
      console.log('Unable to get permission to notify.');
    }
  });
}
requestPermission()

const messaging = getMessaging();
onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
  // ...
});

  }, [])




  // useEffect(() => {
  //   // Request permission to receive notifications
  //   Notification.requestPermission();

  //   // Handle incoming messages
  //   const messaging = firebase.messaging();
  //   messaging.onMessage((payload) => {
  //     const { title, body } = payload.notification;
  //     console.log(payload)
  //     // Show notification to the admin
  //     // new Notification(title, { body });
  //   });

    
  // }, []);


  const { t } = useTranslation();
  return (
    <Page title="Dashboard | My Fans">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "300",
              borderTop: "1px solid #ffffff",
              borderBottom: "1px solid #ffffff",
            }}
          >
            {t("Dashboard")}
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <TotalRegisterUsers />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TotalRegisterCreator />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <TotalGifts />
          </Grid> */}
          <Grid item xs={12} sm={6} md={3}>
            <TotalReports />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            {/* <AppConversionRates /> */}
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {/* <AppCurrentSubject /> */}
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            {/* <AppNewsUpdate /> */}
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {/* <AppOrderTimeline /> */}
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {/* <AppTrafficBySite /> */}
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            {/* <AppTasks /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
