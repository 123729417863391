import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
import {
  getFirestore,
  getDocs,
  collection,
  // addDoc,
  doc,
  setDoc,
  deleteDoc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { getAuth} from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyAg9toHTeUj3eti7T1-QlDGjyanbKislU0",
  authDomain: "myfansapp-4289f.firebaseapp.com",
  projectId: "myfansapp-4289f",
  storageBucket: "myfansapp-4289f.appspot.com",
  messagingSenderId: "548559486000",
  appId: "1:548559486000:web:173e59cd7d1d66871ec1f5",
};


// Initialize Firebase
export const fire = initializeApp(firebaseConfig);
export const db = getFirestore(fire);
export const cloudStorage = getStorage(fire);
export const messaging = getMessaging(fire);
export const getData = async (col) => {
  const snapshot = await getDocs(collection(db, col));
  const data = snapshot.docs.map((doc) => {
    const item = {
      // id: doc.id,
      ...doc.data(),
    };
    return item;
  });
  return data;
};
export const getSingleData = async (col, id) => {
  const myCollectionRef = collection(db, col);
  const myDocRef = doc(myCollectionRef, id);
  const myDocSnapshot = await getDoc(myDocRef);

  if (myDocSnapshot.exists()) {
    const myDocData = myDocSnapshot.data();
    return myDocData;
  } else {
    console.log("Document does not exist");
  }
};
export const setItem = async (col, item) => {
  try {
    const ref = doc(collection(db, col));
    let document = { ...item };
    document.uid = ref.id;
    await setDoc(ref, document);
  } catch (err) {
    console.log(err);
  }
};
export const uploadImage = async (file, address) => {
  const docRef = ref(cloudStorage, address);
  await uploadBytes(docRef, file);
  const URL = await getDownloadURL(docRef);
  return URL;
};
export const updateImage = async (file, old, newAddress) => {
  const docRef = ref(cloudStorage, old);
  await deleteObject(docRef);
  const newRef = ref(cloudStorage, newAddress);
  await uploadBytes(newRef, file);
  const URL = await getDownloadURL(newRef);
  return URL;
};

export const updateItem = async (col, id, item) => {
  try {
    const docRef = doc(db, col, id);
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const token = await currentUser.getIdToken();

    const data = await setDoc(docRef, item, {
      merge: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    console.log('error is', err);
  }
};

export const deleteItem = async (col, id) => {
  try {
    const data = await deleteDoc(doc(db, col, id));
  } catch (err) {
    console.log(err);
  }
};
