import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import bxsUser from "@iconify/icons-bx/bxs-user";
import starFilled from "@iconify/icons-ant-design/star-filled";
import Divider from "@mui/material/Divider";
import closeCircleOutlined from "@iconify/icons-ant-design/close-circle-outlined";
import IconButton from "@mui/material/IconButton";
// import Paper from '@mui/material/Paper';
import Stack from "@mui/material/Stack";
import {
  Grid,
  Tabs,
  Tab,
  Typography,
  Box,
  styled,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import firebase from "firebase/compat";

import {
  doc,
  deleteDoc,
  setDoc,
  updateDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  addDoc,
} from "firebase/firestore";
import { db } from "src/database/db";

import { useLocation, useNavigate } from "react-router-dom";
import Video from "src/components/video/Video";
import { useTranslation } from "react-i18next";
import { UserContext } from "src/Context/userContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, data) {
  return { name, data };
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "#858796",
}));

export default function UserView() {
  const [user, setUser] = useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [notification, setNotification] = useState("");
  const [loading, setLoading] = useState(false);
  const [requestedAction, setRequestedAction] = useState("");
  const [requestedMedia, setRequestedMedia] = useState("");
  const [banAction, setBanAction] = useState(false);

  const { t } = useTranslation();
  // const {state} = useLocation();
  // const state = location.state;
  const state = user;
  const coverPhotos = state.coverPhotos || [];
  const images = state.photos || [];
  const videos = state.videos || [];
  const [lockImages, setLockImages] = useState([]);
  const [publicImages, setPublicImages] = useState([]);
  const [lockVideos, setLockVideos] = useState([]);
  const [publicVideos, setPublicVideos] = useState([]);
  const [successAction, setSuccessAction] = useState(false);

  useEffect(() => {
    setLockImages(images.filter((img) => img.lock === "true"));
    setPublicImages(images.filter((img) => img.lock === "false"));
    setLockVideos(videos.filter((video) => video.lock === "true"));
    setPublicVideos(videos.filter((video) => video.lock === "false"));
  }, []);
  const rows = [
    createData(t("Username"), state.displayName || "not defined"),
    createData(t("Age"), state.age || "not defined"),
    createData(t("Gender"), state.gender || "not defined"),
    createData(t("Mobile"), state.phoneNumber || "not defined"),
    createData(t("Email"), state.email || "not defined"),
    createData(t("Country"), state.country || "not defined"),
    createData(t("Personal_Status"), state.personalStatus || "not defined"),
    state?.isCreator === "approved" &&
      createData(
        t("Subscription Price"),
        state?.sub_scription_price || "not defined"
      ),
    createData(t("Followers"), state.urFansCounter || 0),
    createData(t("Following"), state.myFansConuter || 0),
  ];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();
  const [editClick, setEditClick] = useState(false);
  const [token, setToken] = useState("");

  const deleteUser = async (uid) => {
    try {
      await deleteDoc(doc(db, "users", uid));
      if (state?.isCreator) {
        navigate("/creatorslist");
      } else {
        navigate("/userlist");
      }
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const handleClickOpen = (data) => {
    setRequestedAction(data);
    setOpen(true);
  };
  const handleClickMediaOpen = (data, media) => {
    setOpen(true);
    setRequestedMedia(media);
    setRequestedAction(data);
  };

  const handleUserBlockStatus = async (status, target_id) => {
    const blockUser = collection(db, "users");
    try {
      const querySnapshot = await getDocs(
        query(blockUser, where("uid", "==", target_id))
      );
      querySnapshot.forEach(async (doc) => {
        await updateDoc(doc.ref, { userStatus: status });
        if (status === "Block") {
          await updateDoc(doc.ref, { isBlocked: true });
          setSuccessAction(() => true);
        }
        if (status === "Unblock") {
          await updateDoc(doc.ref, { isBlocked: false });
          setSuccessAction(() => false);
        }
      });

      // also search for the user in the reports collection and update the status of 'report_status' to 'status' value
      const reports = collection(db, "reports");
      // Update existing reports
      const _querySnapshot = await getDocs(
        query(reports, where("target_id", "==", target_id))
      );

      _querySnapshot.forEach(
        async (doc) =>
          await updateDoc(doc.ref, {
            report_status: status,
            target_id: target_id,
            name: querySnapshot.docs[0].data().displayName,
            report_type: "Others",
          })
      );

      // if user doesn't exist in the reports collection, add it
      if (_querySnapshot.empty) {
        await addDoc(collection(db, "reports"), {
          target_id,
          report_status: status,
        });
      }
      if (status === "Unban") {
        setBanAction(() => false);
      }
      if (status === "Per Ban") {
        setBanAction(() => true);
      }
    } catch (error) {
      console.error("Error adding suspended user and report:", error);
      throw error;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const rejectRequest = async (id, text) => {
    setLoading(true);

    try {
      setDoc(doc(db, "users", id), { isCreator: "rejected" }, { merge: true });
      axios.post(
        "https://fcm.googleapis.com/fcm/send",
        {
          notification: {
            body: text,
            title: "My Fans",
          },
          priority: "high",
          data: {
            // ...data,
            click_action: "FLUTTER_NOTIFICATION_CLICK",
            sound: "default",
          },
          to: `/topics/${id}_creator`,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `key=${process.env.REACT_APP_FCM_KEY}`,
          },
        }
      );

      if (requestedAction === "image") {
        handleClose();
        deleteImage(id, requestedMedia);
      } else if (requestedAction === "video") {
        handleClose();
        deleteVideo(id, requestedMedia);
      } else {
        handleClose();
        removeCreator(id);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert("Something went wrong!");
      console.log(err);
    }
  };

  const removeCreator = async (uid) => {
    try {
      setDoc(doc(db, "users", uid), { isCreator: "pending" }, { merge: true });
      navigate("/creatorslist");
    } catch (error) {
      console.error("Error removing creator:", error);
    }
  };

  const handleUpdateToken = (uid) => {
    // try {
    //   setDoc(doc(db, "users", uid), { stars: token }, { merge: true });
    //   navigate("/userlist");
    // } catch (error) {
    //   console.error("Error updating creator:", error);
    // }
  };

  const deleteImage = async (userId, image) => {
    try {
      const userRef = doc(db, "users", userId);
      const userSnapshot = await getDoc(userRef);

      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        const photos = userData.photos || [];
        const index = photos.findIndex((photo) => photo.image === image);
        if (index !== -1) {
          photos.splice(index, 1);
        }
        const updatedImages = images.filter((img) => img.image !== image);
        const updatedLockImages = updatedImages.filter(
          (img) => img.lock === "true"
        );
        const updatedPublicImages = updatedImages.filter(
          (img) => img.lock === "false"
        );
        setLockImages(updatedLockImages);
        setPublicImages(updatedPublicImages);
        await updateDoc(userRef, { photos });
      } else {
        console.error("User document does not exist.");
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const deleteVideo = async (userId, url) => {
    try {
      const userRef = doc(db, "users", userId);
      const userSnapshot = await getDoc(userRef);

      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        const videos = userData.videos || [];
        const index = videos.findIndex((video) => video.video === url);
        const updatedVideos = videos.filter((video) => video.video !== url);
        const updatedLockVideos = updatedVideos.filter(
          (video) => video.lock === "true"
        );
        const updatedPublicVideos = updatedVideos.filter(
          (video) => video.lock === "false"
        );
        setLockVideos(updatedLockVideos);
        setPublicVideos(updatedPublicVideos);
        if (index !== -1) {
          videos.splice(index, 1);
        }
        await updateDoc(userRef, { videos });
      } else {
        console.error("User document does not exist.");
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "#dddfeb" }}>
        <TabsStyle
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <TabStyle label={t("User_Details")} {...a11yProps(0)} />
          <TabStyle label={t("Image")} {...a11yProps(1)} />
          <TabStyle label={t("Videos")} {...a11yProps(2)} />
        </TabsStyle>
      </Box>
      <TabPanelStyle value={value} index={0}>
        <Typography sx={{ mb: "20px", color: "#4e73df" }}>
          {t("User_Details")}
        </Typography>
        <CardStyle>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>{t("User_Details")}</StyledTableCell>
                      <StyledTableCell>
                        {state.isBlocked === true ? "Blocked" : "Unblocked"}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell component="th">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.data}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={4}>
              <Box
                component="img"
                src={state.photoURL}
                sx={{
                  objectFit: "contain",
                  aspectRatio: "1",
                  width: "80%",
                  borderRadius: "50%",
                  background: "rgba(0,0,0,0.05)",
                  mx: "auto",
                  mb: "20px",
                }}
              />
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={1}
                sx={{ justifyContent: "center", pb: "20px" }}
              >
                <Item>
                  <Icon icon={bxsUser} /> {state.urFansCounter || 0}
                </Item>
                <Item>
                  <Icon icon={bxsUser} /> {state.myFansConuter || 0}
                </Item>
                <Item>
                  <Icon icon={starFilled} /> {state.stars || 0}
                </Item>
              </Stack>
              <Grid
                container
                item
                sx={{ gap: "10px", my: "15px" }}
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  sx={{ bgcolor: "error.main", color: "white" }}
                  onClick={() => deleteUser(state?.uid)}
                >
                  Delete
                </Button>
                {/* <Button
                  variant="contained"
                  sx={{ bgcolor: "info.main", color: "white" }}
                  onClick={() => setEditClick(!editClick)}
                >
                  Edit Token
                </Button> */}
                {state?.isCreator === "approved" && (
                  <Button
                    variant="contained"
                    sx={{ bgcolor: "secondary.main", color: "white" }}
                    onClick={() => handleClickOpen("creator")}
                  >
                    Remove Creator
                  </Button>
                )}
              </Grid>
              {/* create 3 buttons for suspend block and per ban */}
              <Grid
                container
                item
                sx={{ gap: "10px", my: "15px" }}
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  sx={{ bgcolor: "success.main", color: "white" }}
                  onClick={() => handleUserBlockStatus("Suspend", state?.uid)}
                >
                  Suspend
                </Button>
                {state?.isBlocked == false && successAction == false ? (
                  <Button
                    variant="contained"
                    sx={{ bgcolor: "warning.main", color: "white" }}
                    onClick={() => handleUserBlockStatus("Block", state?.uid)}
                  >
                    Block
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{ bgcolor: "warning.main", color: "white" }}
                    onClick={() => handleUserBlockStatus("Unblock", state?.uid)}
                  >
                    Unblock
                  </Button>
                )}

                {state?.userStatus == "Per Ban" || banAction == true ? (
                  <Button
                    variant="contained"
                    sx={{ bgcolor: "error.main", color: "white" }}
                    onClick={() => handleUserBlockStatus("Unban", state?.uid)}
                  >
                    Unban
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{ bgcolor: "error.main", color: "white" }}
                    onClick={() => handleUserBlockStatus("Per Ban", state?.uid)}
                  >
                    Permanently Ban
                  </Button>
                )}
              </Grid>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Why Reject</DialogTitle>
                <DialogContent>
                  <TextField
                    label="Enter Your reason"
                    type="text"
                    fullWidth
                    multiline
                    value={notification}
                    minRows={4}
                    sx={{ mt: 1 }}
                    onChange={(e) => setNotification(e.target.value)}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => rejectRequest(state?.uid, notification)}
                  >
                    Submit
                  </Button>
                  <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
              </Dialog>
              {/* {editClick && (
                <Grid container item flexDirection="column">
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    placeholder="Enter Token"
                    onChange={(e) => setToken(e.target.value)}
                    name="token"
                    value={token}
                    validators={["required", "isEmail"]}
                    errorMessages={
                      ("This field is required", "Email is not Valid")
                    }
                    autoComplete="off"
                  />
                  <Grid container item sx={{ gap: "10px", mt: "5px" }}>
                    <Button
                      variant="contained"
                      sx={{ bgcolor: "success.main", color: "white" }}
                      onClick={() => handleUpdateToken(state?.uid)}
                    >
                      Update
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ bgcolor: "error.main", color: "white" }}
                      onClick={() => setEditClick(false)}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              )} */}
              <Grid container spacing={2}>
                {coverPhotos.map((photo, index) => (
                  <Grid item key={index} xs={6}>
                    <ImageStyle component="img" src={photo} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </CardStyle>
      </TabPanelStyle>
      <TabPanelStyle value={value} index={1}>
        <Typography sx={{ mb: "20px" }}>{t("User_Images")}</Typography>
        <Grid
          container
          direction="column"
          sx={{
            background: "#fff",
            p: 2,
            borderRadius: "10px",
          }}
        >
          <Grid item>
            <Typography
              variant="h6"
              sx={{ fontWeight: 400, color: "#000", marginBottom: "1rem" }}
              component="div"
            >
              {t("Lock_Images")}
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            {lockImages?.length === 0 && (
              <Grid container justifyContent="center" sx={{ p: 4 }}>
                <Typography varaint="subtitile2" component="div">
                  {t("No_Lock_Images")}
                </Typography>
              </Grid>
            )}
            {lockImages?.map((img, index) => (
              <Grid key={index} item xs={3}>
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    maxWidth: "100%",
                    mb: "7px",
                    cursor: "pointer",
                  }}
                >
                  <Icon
                    icon={closeCircleOutlined}
                    color="red"
                    onClick={() => handleClickMediaOpen("image", img.image)}
                  />
                </Grid>
                <ImageStyle component="img" src={img.image} />
                <Stack
                  direction="row"
                  sx={{ py: 1 }}
                  justifyContent="space-between"
                >
                  <Typography component="div">{t("Price")}</Typography>
                  <Typography component="div">{img.price}</Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Why Reject</DialogTitle>
            <DialogContent>
              <TextField
                label="Enter Your reason"
                type="text"
                fullWidth
                multiline
                value={notification}
                minRows={4}
                sx={{ mt: 1 }}
                onChange={(e) => setNotification(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => rejectRequest(state?.uid, notification)}>
                Submit
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </Dialog>
          <Grid item>
            <Typography
              variant="h6"
              sx={{ fontWeight: 400, color: "#000", marginBottom: "1rem" }}
              component="div"
            >
              {t("Public_Images")}
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            {publicImages?.length === 0 && (
              <Grid container justifyContent="center" sx={{ p: 4 }}>
                <Typography varaint="subtitile2" component="div">
                  {t("No_Public_Images")}
                </Typography>
              </Grid>
            )}
            {publicImages?.map((img, index) => (
              <Grid key={index} item xs={3}>
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    maxWidth: "100%",
                    mb: "7px",
                    cursor: "pointer",
                  }}
                >
                  <Icon
                    icon={closeCircleOutlined}
                    color="red"
                    onClick={() => handleClickMediaOpen("image", img.image)}
                  />
                </Grid>
                <ImageStyle component="img" src={img.image} />
              </Grid>
            ))}
          </Grid>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Why Reject</DialogTitle>
            <DialogContent>
              <TextField
                label="Enter Your reason"
                type="text"
                fullWidth
                multiline
                value={notification}
                minRows={4}
                sx={{ mt: 1 }}
                onChange={(e) => setNotification(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => rejectRequest(state?.uid, notification)}>
                Submit
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </TabPanelStyle>
      <TabPanelStyle value={value} index={2}>
        <Typography sx={{ mb: "20px" }}>{t("User_Videos")}</Typography>
        <Grid
          container
          direction="column"
          sx={{
            background: "#fff",
            p: 2,
            borderRadius: "10px",
          }}
        >
          <Grid item>
            <Typography
              variant="h6"
              sx={{ fontWeight: 400, color: "#000", marginBottom: "1rem" }}
              component="div"
            >
              {t("Lock_Videos")}
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            {lockVideos.length === 0 && (
              <Grid container justifyContent="center" sx={{ p: 4 }}>
                <Typography varaint="subtitile2" component="div">
                  {t("No_Lock_Videos")}
                </Typography>
              </Grid>
            )}
            {lockVideos.map((video, index) => (
              <Grid key={index} item xs={3}>
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    maxWidth: "100%",
                    mb: "7px",
                    cursor: "pointer",
                  }}
                >
                  <Icon
                    icon={closeCircleOutlined}
                    color="red"
                    onClick={() => handleClickMediaOpen("video", video.video)}
                  />
                </Grid>
                <Video URL={video.video} />
                <Stack
                  direction="row"
                  sx={{ py: 1 }}
                  justifyContent="space-between"
                >
                  <Typography component="div">{t("Price")}</Typography>
                  <Typography component="div">{video.price}</Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Why Reject</DialogTitle>
            <DialogContent>
              <TextField
                label="Enter Your reason"
                type="text"
                fullWidth
                multiline
                value={notification}
                minRows={4}
                sx={{ mt: 1 }}
                onChange={(e) => setNotification(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => rejectRequest(state?.uid, notification)}>
                Submit
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </Dialog>
          <Grid item>
            <Typography
              variant="h6"
              sx={{ fontWeight: 400, color: "#000", marginBottom: "1rem" }}
              component="div"
            >
              {t("Public_Videos")}
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            {publicVideos.length === 0 && (
              <Grid container justifyContent="center" sx={{ p: 4 }}>
                <Typography varaint="subtitile2" component="div">
                  {t("No_Public_Videos")}
                </Typography>
              </Grid>
            )}
            {publicVideos.map((video, index) => (
              <Grid key={index} item xs={3}>
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    maxWidth: "100%",
                    mb: "7px",
                    cursor: "pointer",
                  }}
                >
                  <Icon
                    icon={closeCircleOutlined}
                    color="red"
                    onClick={() => handleClickMediaOpen("video", video.video)}
                  />
                </Grid>
                <Video URL={video.video} />
              </Grid>
            ))}
          </Grid>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Why Reject</DialogTitle>
            <DialogContent>
              <TextField
                label="Enter Your reason"
                type="text"
                fullWidth
                multiline
                value={notification}
                minRows={4}
                sx={{ mt: 1 }}
                onChange={(e) => setNotification(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => rejectRequest(state?.uid, notification)}>
                Submit
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </TabPanelStyle>
    </Box>
  );
}

const ImageStyle = styled((props) => <Box {...props} />)(({ theme }) => ({
  objectFit: "cover",
  aspectRatio: "1",
  borderRadius: "10px",
  width: "100%",
  aspectRatio: "1/1.5",
}));

const TabStyle = styled((props) => <Tab {...props} />)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: "#fff",
    color: "#6e707e",
  },
}));

const TabsStyle = styled((props) => <Tabs {...props} />)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    display: "none",
  },
}));

const TabPanelStyle = styled((props) => <TabPanel {...props} />)(
  ({ theme }) => ({
    color: "#6e707e",
  })
);

const CardStyle = styled((props) => <Card {...props} />)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: "50px 20px",
  borderRadius: "7px",
}));
