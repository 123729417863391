import { filter, merge } from "lodash";
// import { Icon } from "@iconify/react";
// import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
// import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Card,
  Table,
  // Stack,
  // Avatar,
  Button,
  // Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  // Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// components
import Page from "../../components/Page";
// import Label from "../../components/Label";
import Scrollbar from "../../components/Scrollbar";
import SearchNotFound from "../../components/SearchNotFound";
import {
  TableTitle,
  TableHeader,
  // UserListToolbar,
  // UserMoreMenu,
} from "../../components/_dashboard/user";
//
import USERLIST from "../../_mocks_/PendingRequest";
import { useTranslation } from "react-i18next";
import {
  db,
  getData,
  getSingleData,
  setItem,
  updateItem,
} from "src/database/db";

import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import qs from "qs";
import axios from "axios";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserList() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  // const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10000000);
  const { t } = useTranslation();
  // redeems don't need to be in the redux state
  const [redeemList, setredeemList] = useState([]);
  const [transactionDetails, settransactionDetails] = useState(null);
  const [payoutsInitiated, setpayoutsInitiated] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false)

  useEffect(() => {
    getDocs(query(collection(db, "redeems"), where("status", "==", "pending")))
      .then((snapshot) => {
        const list = snapshot.docs.map((doc) => {
          try {
            return { ...doc.data() };
          } catch (err) {
            console.log(err);
          }
        });
        setredeemList(list);
        console.log("redeemList is", list);
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log(redeemList);
  }, [payoutsInitiated, isDeclined]);

  const createAuthToken = async () => {
    try {
      const res = await axios.post(
        // "https://api-m.sandbox.paypal.com/v1/oauth2/token",
        "https://api-m.paypal.com/v1/oauth2/token",
        "grant_type=client_credentials",
        {
          headers: { "content-type": "application/x-www-form-urlencoded" },
          auth: {
            username:
              // "AY_sKn0tNezuu-Dxb5B077RvWAekxNMCEUqE8pfu2e1TCg_8KcqttmtcHZ78PKt8o8W6-XtZmHVwm_Zk",
              process.env.REACT_APP_PAYOUTS_USERNAME,
            password:
              // "EArQOYDP55gcDbVm3z4lH3pfPbiaDA4gRsecVFQahrUuT_q0h393pPhjmQByR7y0R0KXumdy1VWHz9-n",
              process.env.REACT_APP_PAYOUTS_PASSWORD,
          },
        }
      );
      console.log("createAuthToken res is", res);
      return res;
    } catch (err) {
      console.log("createAuthToken failed");
    }
  };

  const handleDecline = async (redeemUID) => {
    console.log('redeemUID is', redeemUID)
    await updateItem("redeems", redeemUID.replace(/\s+/g, ""), {
      status: "decline",
    });
  }

  const handlePaypal = async (
    redeemTranNumber,
    redeemUID,
    recieverPaypal,
    amount
  ) => {
   
    // Go to the database see when was the last auth token was created
    settransactionDetails(true);
    const res = await getData("redeemAuth");
    const redeemAuth = res[0];
    const date = Math.floor(Date.now() / 1000);
    let authToken;


    try {
      if (
        // If the authentication code is never generated.
        redeemAuth.creationTime === 0 ||
        redeemAuth.redeemAuth === "" ||
        // If the current time has exceeded the code expiration time
        date > redeemAuth.creationTime + 32000
      ) {
        const res = await createAuthToken();

        authToken = res.data.access_token;
        const currentDate = Math.floor(Date.now() / 1000);


        // Update the firebase accordingly
        await updateItem("redeemAuth", "qou08ysPC29GVfcRJyD5", {
          creationTime: currentDate,
          redeemAuth: authToken,
          lastTransaction: redeemAuth.lastTransaction + 1,
        });
        // console.log("created Auth Token, Updated Firebase");
      } else {
        authToken = redeemAuth.redeemAuth;
        await updateItem("redeemAuth",
          "qou08ysPC29GVfcRJyD5"
        , {
          lastTransaction: redeemAuth.lastTransaction + 1,
        });
      }
    } catch (e) {
      settransactionDetails(false);
      console.log("Transaction Unsuccessful!");
    }

    // Bring its value check if my time is greater than (that value+32400, give 400 seconds as buffer) if it is smaller use that token (bring the field to client, decode it),
    // otherwise create another token, encode it and send your current time to the database.

    // Now integrating Payment Api
    try {
      const payoutRes = await axios.post(
        // "https://api-m.sandbox.paypal.com/v1/payments/payouts",
        "https://api-m.paypal.com/v1/payments/payouts",
        {
          sender_batch_header: {
            sender_batch_id: `${redeemAuth.lastTransaction}`,

            email_subject: "Here's your payout!",
            email_message: "Keep rocking on MyFans!",
          },
          items: [
            {
              recipient_type: "EMAIL",
              amount: {
                value: amount,
                currency: "USD",
              },
              sender_item_id: `${redeemAuth.lastTransaction + 1}`,
              recipient_wallet: "PAYPAL",
              receiver: recieverPaypal,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      const transactionInfoLink = payoutRes.data.links[0].href;
      

      const transactionDetailsRes = await axios.get(transactionInfoLink, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      


      settransactionDetails(false);
    } catch (err) {
      console.log("Payouts Api Failed");
      settransactionDetails(false);
      console.log(err);
    }
  };

  const TABLE_HEAD = [
    { id: "sno", label: "#", alignRight: false },
    { id: "daterequested", label: "Date", alignRight: false },
    { id: "name", label: t("User_Name"), alignRight: false },
    { id: "paypalId", label: t("Email"), alignRight: false },
    { id: "stars", label: t("Stars"), alignRight: false },
    { id: "totalamount", label: t("Total_Amount"), alignRight: false },
    { id: "payableamount", label: t("Payable_Amount"), alignRight: false },
    { id: "method", label: t("Method"), alignRight: false },
    { id: "action", label: t("Action"), alignRight: false },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  // 	const selectedIndex = selected.indexOf(name);
  // 	let newSelected = [];
  // 	if (selectedIndex === -1) {
  // 		newSelected = newSelected.concat(selected, name);
  // 	} else if (selectedIndex === 0) {
  // 		newSelected = newSelected.concat(selected.slice(1));
  // 	} else if (selectedIndex === selected.length - 1) {
  // 		newSelected = newSelected.concat(selected.slice(0, -1));
  // 	} else if (selectedIndex > 0) {
  // 		newSelected = newSelected.concat(
  // 			selected.slice(0, selectedIndex),
  // 			selected.slice(selectedIndex + 1)
  // 		);
  // 	}
  // 	setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleFilterByName = (event) => {
  // 	setFilterName(event.target.value);
  // };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(
    redeemList,
    getComparator(order, orderBy)
    // filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const TableCellStyles = styled((props) => <TableCell {...props} />)(
    ({ theme }) => ({
      border: "1px solid #e3e6f0",
      fontSize: "12px",
    })
  );

  return (
    <Page title="Money Request | Pending Request">
      <Container>
        {/* <Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mb={5}
				>
					<Typography variant="h4" gutterBottom>
						User
					</Typography>
					<Button
						variant="contained"
						component={RouterLink}
						to="#"
						startIcon={<Icon icon={plusFill} />}
					>
						New User
					</Button>
				</Stack> */}

        <Card sx={{ borderRadius: "5px" }}>
          <TableTitle title={t("Pending_Request")} />
          {/* <UserListToolbar
						numSelected={selected.length}
						filterName={filterName}
						onFilterName={handleFilterByName}
					/> */}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, p: "18px" }}>
              <Table>
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        date_requested,
                        id,
                        method,
                        name,
                        payable_amount,
                        paypal_id,
                        stars,
                        status,
                        total_amount,
                        transaction_number,
      
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
														<Checkbox
															checked={isItemSelected}
															onChange={(event) => handleClick(event, name)}
														/>
													</TableCell> */}
                          <TableCellStyles
                            // sx={{ border: "1px solid red" }}
                            align="left"
                          >
                            {index + 1}
                          </TableCellStyles>
                          <TableCellStyles align="left">
                            {date_requested}
                          </TableCellStyles>
                          <TableCellStyles align="left">{name}</TableCellStyles>
                          <TableCellStyles align="left">
                            {paypal_id}
                          </TableCellStyles>
                          <TableCellStyles align="left">
                            {stars}
                          </TableCellStyles>
                          <TableCellStyles align="left">
                            {total_amount}
                          </TableCellStyles>
                          <TableCellStyles align="left">
                            {payable_amount}
                          </TableCellStyles>
                          <TableCellStyles align="left">
                            {method}
                          </TableCellStyles>
                          <TableCellStyles align="left">
                            <Button
                              variant="contained"
                              disabled={transactionDetails}
                              // component={RouterLink}
                              // to="#"
                              sx={{
                                borderRadius: "6px",
                                boxShadow: "none",
                                backgroundColor: "info.main",
                              }}
                              onClick={async () => {
                                await handlePaypal(
                                  transaction_number,
                                  id,
                                  paypal_id,
                                  payable_amount
                                );
                                setpayoutsInitiated(!payoutsInitiated);
                              }}
                            >
                              {!transactionDetails ? (
                                t("Paypal")
                              ) : (
                                <div>
                                  <CircularProgress size={15} />
                                </div>
                              )}
                            </Button>
                          </TableCellStyles>
                          <TableCellStyles align="left">
                            <Button
                              variant="contained"
                              component={RouterLink}
                              to="#"
                              sx={{
                                borderRadius: "6px",
                                boxShadow: "none",
                                backgroundColor: "#e74a3b",
                              }}
                              onClick={async () => {
                                await handleDecline(
                                  id,
                                );
                                setIsDeclined(!isDeclined);
                              }}
                            >
                              {t("Decline")}
                            </Button>
                          </TableCellStyles>
                          {/* <TableCellStyles align="left">
														<Button
															variant="contained"
															component={RouterLink}
															to="#"
														>
															Edit
														</Button>
													</TableCellStyles>
													<TableCellStyles align="left">
														<Button
															variant="contained"
															component={RouterLink}
															to="#"
														>
															Delete
														</Button>
													</TableCellStyles>
													<TableCell align="right">
														<UserMoreMenu />
													</TableCell> */}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCellStyles colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCellStyles
                        align="center"
                        colSpan={6}
                        sx={{ py: 3 }}
                      >
                        {/* searchQuery={filterName} */}
                        <SearchNotFound />
                      </TableCellStyles>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          {/* <TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={USERLIST.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/> */}
        </Card>
      </Container>
    </Page>
  );
}
