// scroll bar
import "simplebar/src/simplebar.css";

import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

//
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

// imp----------------------------------------------------------------------
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    resources: {
      en: {
        translation: {
          Creator_Name: "Creator Name",
          Dashboard: "Dashboard",
          List_of_Creators: "List of Creators",
          Send: "Send",
          List_of_Users: "List of Users",
          List_of_Admin: "List of Admin",
          Admin_Details: "Admin Details",
          User_Management: "User Management",
          Mass_Message: "Mass Message",
          Inquiry: "Inquiry",
          Money_Request: "Money Request",
          Video: "Video",
          Creator_Request: "Creator Request",
          Gifts: "Gifts",
          Reports: "Reports",
          Add_Admin: "Add Admin",
          List_Admin: "List Admin",
          User_List: "User List",
          Creator_List: "Creator List",
          Pending_Request: "Pending Request",
          Accept_Request: "Accept Request",
          Decline_Request: "Decline Request",
          Gifts_Details: "Gifts Details",
          List_Gifts: "List Gifts",
          "Search...": "Search...",
          Users: "Users",
          Creators: "Creators",
          Earnings_Overview: "Earnings Overview",
          Current_VIsits: "Current Visits",
          Profile: "Profile",
          Update_Password: "Update Password",
          Logout: "Logout",
          Admin: "Admin",
          Welcome_Back: "Welcome Back",
          Email: "Email",
          Password: "Password",
          Remember_me: "Remember me",
          Sign_In: "Sign In",
          Add_Image: "Add Image",
          Upload: "Upload",
          Submit: "Submit",
          Cancel: "Cancel",
          Name: "Name",
          Type: "Type",
          Super_Admin: "Super Admin",
          Phone: "Phone",
          Phone_no: "Phone no",
          Gender: "Gender",
          Male: "Male",
          Female: "Female",
          Active: "Active",
          Blocked: "Blocked",
          Email_address: "Email address",
          Status: "Status",
          Action: "Action",
          "S.no": "S.no",
          Update: "Update",
          User: "User",
          Sign_Up_With: "Sign Up With",
          Age: "Age",
          Country: "Country",
          Langauge: "Language",
          Followers: "Followers",
          Following: "Following",
          Stars: "Stars",
          Personal_Status: "Personal Status",
          Terms: "Terms",
          Not_Found: "Not Found",
          Send_message_to_My_Fans_community:
            "Send mass message to My Fans community",
          "Send_message_to_community...": "Send message to community...",
          Back: "Back",
          Save: "Save",
          User_Name: "User Name",
          Total_Amount: "Total Amount",
          Payable_Amount: "Payable Amount",
          Method: "Method",
          Paypal: "Paypal",
          Decline: "Decline",
          Amount: "Amount",
          Transaction_ID: "Transaction ID",
          Paid: "Paid",
          Uploaded_By: "Uploaded By",
          Filename: "Filename",
          Video_View: "Video View",
          Creator_Name: "Creator Name",
          Doc_Type: "Doc Type",
          Face_Id: "Face Id",
          Id_Card: "Id Card",
          Accept: "Accept",
          Deny: "Deny",
          View: "View",
          Image: "Image",
          Videos: "Videos",
          Username: "Username",
          Mobile: "Mobile",
          Lock_Images: "Lock Images",
          No_Lock_Images: "No Lock Images",
          Public_Images: "Public Images",
          No_Public_Images: "No Public Images",
          Lock_Videos: "Lock Videos",
          Public_Videos: "Publick Videos",
          Price: "Price",
          Enter_Name: "Enter Name",
          Classic: "Classic",
          Party: "Party",
          Moods: "Moods",
          Collections: "Collections",
          Games: "Games",
          Uploads: "Uploads",
          Points: "Points",
          Number_only: "Number only",
          Gift_Details: "Gift Details",
          Delete: "delete",
          User_Details: "User Details",
          Creators: "Creators",
          List_of_Videos: "List of Videos",
          List_of_Gifts: "List of Gifts",
          Text: "Text",
          Reported_Against: "Reported Against",
          Reported_By: "Reported By",
          List_of_Reports: "List of Reports",
          User_Images: "User Images",
          User_Videos: "User Videos",
          No_Lock_Videos: "No Lock Videos",
          No_Public_Videos: "No Public Videos",
          Requests: "Requests",
          Video_Details: "Video Details",
          Ratings: "Ratings",
          Notifications: "Notifications",
          Confirm_Password: "Confirm Password",
          Old_Password: "Old Password",
          New_Password: "New Password",
        },
      },
      es: {
        translation: {
          Creator_Name: "Nombre del creador",
          Confirm_Password: "Confirmar contraseña",
          Old_Password: "Contraseña anterior",
          New_Password: "Nueva contraseña",
          Notifications: "Notificaciones",
          Ratings: "Calificaciones",
          Video_Details: "Detalles del vídeo",
          Requests: "Peticiones",
          No_Public_Videos: "Sin videos públicos",
          No_Lock_Videos: "Vídeos sin bloqueo",
          User_Videos: "Vídeos de usuario",
          User_Images: "Imágenes de usuario",
          Text: "Texto",
          Reported_Against: "Reportado contra",
          Reported_By: "Reportado por",
          List_of_Reports: "Lista de informes",
          List_of_Videos: "Lista de videos",
          Dashboard: "Tablero de mandos",
          List_of_Creators: "Lista de creadores",
          Send: "Enviar",
          List_of_Gifts: "Lista de regalos",
          Creators: "Creadores",
          User_Details: "Detalles de usuario",
          List_of_Users: "Lista de usuarios",
          List_of_Admin: "Lista de administradores",
          Admin_Details: "Detalles del administrador",
          User_Management: "Gestión de usuarios",
          Mass_Message: "Mensaje masivo",
          Inquiry: "Consulta",
          Money_Request: "Solicitud de dinero",
          Video: "Video",
          Creator_Request: "Solicitud del creador",
          Gifts: "Regalos",
          Reports: "Informes",
          Add_Admin: "Agregar administrador",
          List_Admin: "Administrador de lista",
          User_List: "Lista de usuarios",
          Creator_List: "Lista de creadores",
          Pending_Request: "Solicitud pendiente",
          Accept_Request: "Aceptar petición",
          Decline_Request: "Solicitud de rechazo",
          Gifts_Details: "Detalles de regalos",
          List_Gifts: "Lista de regalos",
          "Search...": "Búsqueda...",
          Users: "Usuarios",
          Creators: "Creadores",
          Earnings_Overview: "Resumen de ganancias",
          Current_VIsits: "Visitas actuales",
          Profile: "Perfil",
          Update_Password: "Actualiza contraseña",
          Logout: "Cerrar sesión",
          Admin: "Administrador",
          Welcome_Back: "Bienvenido de nuevo",
          Email: "Correo electrónico",
          Password: "Contraseña",
          Remember_me: "Recuérdame",
          Sign_In: "Iniciar sesión",
          Add_Image: "Añadir imagen",
          Upload: "Subir",
          Submit: "Entregar",
          Cancel: "Cancelar",
          Name: "Nombre",
          Type: "Escribe",
          Super_Admin: "Superadministrador",
          Phone: "Teléfono",
          Phone_no: "Telefono no",
          Gender: "Género",
          Male: "Masculino",
          Female: "Mujer",
          Active: "Activo",
          Blocked: "Obstruido",
          Email_address: "Dirección de correo electrónico",
          Status: "Estado",
          Action: "Acción",
          "S.no": "S.no",
          Update: "Actualizar",
          User: "Usuario",
          Sign_Up_With: "Registrarte con",
          Age: "Envejecer",
          Country: "País",
          Langauge: "Idioma",
          Followers: "Seguidores",
          Following: "Siguiente",
          Stars: "Estrellas",
          Personal_Status: "Estado personal",
          Terms: "Condiciones",
          Not_Found: "No encontrado",
          Send_message_to_My_Fans_community:
            "Enviar mensaje masivo a la comunidad Mis Fans",
          "Send_message_to_community...": "Enviar mensaje a la comunidad...",
          Back: "atrás",
          Save: "Ahorrar",
          User_Name: "Nombre de usuario",
          Total_Amount: "Cantidad total",
          Payable_Amount: "Cantidad a pagar",
          Method: "Método",
          Paypal: "Paypal",
          Decline: "Disminución",
          Amount: "Monto",
          Transaction_ID: "ID de transacción",
          Paid: "Pagado",
          Uploaded_By: "Subido por",
          Filename: "Nombre del archivo",
          Video_View: "Vista de vídeo",
          Creator_Name: "Nombre del creador",
          Doc_Type: "Tipo de documento",
          Face_Id: "identificación de la cara",
          Id_Card: "Tarjeta de identificación",
          Accept: "Aceptar",
          Deny: "Negar",
          View: "Vista",
          Image: "Imagen",
          Videos: "Vídeos",
          Username: "Nombre de usuario",
          Mobile: "Móvil",
          Lock_Images: "Imágenes de bloqueo",
          No_Lock_Images: "Sin imágenes de bloqueo",
          Public_Images: "Imágenes públicas",
          No_Public_Images: "Sin imágenes públicas",
          Lock_Videos: "Bloquear vídeos",
          Public_Videos: "Vídeos públicos",
          Price: "Precio",
          Enter_Name: "Ingrese su nombre",
          Classic: "Clásico",
          Party: "Fiesta",
          Moods: "Estados de ánimo",
          Collections: "Colecciones",
          Games: "Juegos",
          Uploads: "Cargas",
          Points: "Puntos",
          Number_only: "Solo número",
          Gift_Details: "Detalles del regalo",
          Delete: "Eliminar",
        },
      },
    },
    fallbackLng: "en",

    // interpolation: {
    //   escapeValue: false,
    // },
    detection: {
      order: [
        "cookie",
        "htmlTag",
        "localStorage",
        "sessionStorage",
        "path",
        "subdomain",
      ],
      caches: ["cookie"],
    },
    react: {
      useSuspense: false,
    },
  });

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById("root")
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
