import PropTypes from "prop-types";
// material
import { visuallyHidden } from "@mui/utils";
import {
	Box,
	// Checkbox,
	TableRow,
	TableCell,
	TableHead,
	TableSortLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// ----------------------------------------------------------------------

TableHeader.propTypes = {
	order: PropTypes.oneOf(["asc", "desc"]),
	orderBy: PropTypes.string,
	rowCount: PropTypes.number,
	headLabel: PropTypes.array,
	numSelected: PropTypes.number,
	onRequestSort: PropTypes.func,
	onSelectAllClick: PropTypes.func,
};
const TableCellStyles = styled((props) => <TableCell {...props} />)(
	({ theme }) => ({
		border: "1px solid #e3e6f0",
	})
);
export default function TableHeader({
	order,
	orderBy,
	rowCount,
	headLabel,
	numSelected,
	onRequestSort,
	onSelectAllClick,
}) {
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headLabel.map((headCell) => (
					<TableCellStyles
						key={headCell.id}
						align={headCell.alignRight ? "right" : "left"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							hideSortIcon
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box sx={{ ...visuallyHidden }}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCellStyles>
				))}
			</TableRow>
		</TableHead>
	);
}
