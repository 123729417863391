import { useState, useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import {
  setAdmins,
  setCreatorRequest,
  setCreators,
  setGifts,
  setUsers,
  setVideos,
  setAdminData,
  setReports,
  setNotifications
} from "../../store";
import { useDispatch } from "react-redux";
import { db, getData, getSingleData } from "../../database/db";
import {
  // getDocs,
  where,
  query,
  collection,
  onSnapshot,
  getDocs,
  // getDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { AuthContext } from "src/Context/authContext";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout(props) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [auth, setAuth] = useContext(AuthContext);

  useEffect(async () => {
    const un1 = onSnapshot(collection(db, "users"), (snapshot) => {
      const list = snapshot.docs.map((doc) => doc.data());
      dispatch(setUsers(list));
    });

    const un2 = onSnapshot(collection(db, "admin"), (snapshot) => {
      const list = snapshot.docs.map((doc) => doc.data());
      dispatch(setAdmins(list));
    });
    const creatorsRef = query(
      collection(db, "users"),
      where("isCreator", "==", "approved")
    );

    // getDocs(
    //   query(collection(db, "users"), where("isCreator", "==", "approved"))
    // ).then((snapshot) => {
    //   const list = snapshot.docs.map((doc) => {
    //     try {
    //       // let response = await getDocs(
    //       //   collection(db, `users/${doc.id}/creator`)
    //       // );
    //       // let creatorData = response.docs[0].data();
    //       // return { ...doc.data(), ...creatorData };
    //       return { ...doc.data(), id: doc.id };
    //     } catch (err) {
    //       console.log(err);
    //     }
    //   });
    //   dispatch(setCreators(list));
    //   // Promise.all(list).then((data) => {
    //   //   dispatch(setCreators(data));
    //   // });
    // });

    onSnapshot(creatorsRef, (snapshot) => {
      const list = snapshot.docs.map((doc) => {
        try {
          // let response = await getDocs(
          //   collection(db, `users/${doc.id}/creator`)
          // );
          // let creatorData = response.docs[0].data();
          // return { ...doc.data(), ...creatorData };
          return { ...doc.data(), id: doc.id };
        } catch (err) {
          console.log(err);
        }
      });
      dispatch(setCreators(list));
    });
    const un4 = onSnapshot(collection(db, "gifts"), (snapshot) => {
      const list = snapshot.docs.map((doc) => doc.data());
      dispatch(setGifts(list));
    });

    const un5 = onSnapshot(collection(db, "reports"), (snapshot) => {
      const list = snapshot.docs.map((doc) => doc.data());
      dispatch(setReports(list));
    });

    const un6 = onSnapshot(collection(db, "notifyAdmin"), (snapshot) => {

      const list = snapshot.docs
        .filter(
          (doc) =>
            doc.data()?.notifyAdmin &&
            doc.data()?.notifyAdmin?.isRead != undefined &&
            doc.data()?.notifyAdmin?.isRead === false
        )
        .map((doc) => doc.data());
      // let list = snapshot.docs.map((doc) => doc.data());
      // console.log("lists are@@@", list);

      //  list.sort((a, b) => {
      //    const aIsRead = a.notifyAdmin && a.notifyAdmin.isRead;
      //    const bIsRead = b.notifyAdmin && b.notifyAdmin.isRead;

      //    if (!aIsRead && bIsRead) {
      //      return -1;
      //    } else if (aIsRead && !bIsRead) {
      //      return 1;
      //    } else {
      //      return 0;
      //    }
      //  });
      dispatch(setNotifications(list));
    });

    /*
    const list = snapshot.docs
       .filter(
         (doc) =>
           doc.data().notifyAdmin &&
           doc.data().notifyAdmin.isRead!=undefined &&
           doc.data().notifyAdmin.isRead === false
       )
       .map((doc) => doc.data());
    */

    // getDocs(
    //   query(collection(db, "users"), where("isCreator", "==", "pending"))
    // ).then((snapshot) => {
    //   const list = snapshot.docs.map((doc) => {
    //     // console.log(doc.id);
    //     // let response = await getDocs(collection(db, `users/${doc.id}/creator`));
    //     // if (response.docs.length >= 1) {
    //     //   let creatorData = response.docs[0].data();
    //     //   // console.log(creatorData);
    //     //   return { ...doc.data(), ...creatorData };
    //     // }
    //     try {
    //       return { ...doc.data(), id: doc.id };
    //     } catch (err) {
    //       console.log(err);
    //     }
    //   });
    //   dispatch(setCreatorRequest(list));
    //   // Promise.all(list).then((data) => {
    //   //   // console.log(data);
    //   //   if (data) {
    //   //     // console.log(data);
    //   //     dispatch(setCreatorRequest(data));
    //   //   }
    //   // });
    // });

    const creatorRequestsRef = query(
      collection(db, "users"),
      where("isCreator", "==", "pending")
    );

    onSnapshot(creatorRequestsRef, (snapshot) => {
      const list = snapshot.docs.map((doc) => {
        try {
          // let response = await getDocs(collection(db, `users/${doc.id}/creator`));
          // if (response.docs.length >= 1) {
          //   let creatorData = response.docs[0].data();
          //   return { ...doc.data(), ...creatorData };
          // }
          return { ...doc.data(), id: doc.id };
        } catch (err) {
          console.log(err);
        }
      });
      dispatch(setCreatorRequest(list));
    });

    getDocs(collection(db, "users")).then((snapshot) => {
      const list = snapshot.docs.map((doc) => doc.data());
      dispatch(setVideos(list));
    });

    onSnapshot(doc(db, "admin", auth.userId), (snapshot) => {
      dispatch(setAdminData(snapshot.data()));
    });

    const a = await getSingleData("admin", auth.userId);

    // Getting reports

    getDocs(collection(db, "reports")).then((snapshot) => {
      const reportsList = snapshot.docs.map((doc) => doc.data());

      const generateTempReports = async () => {
        const reportListTemp = [...reportsList];
        const promises = reportsList.map(async (report, index) => {
          // if report is undefined
          const { reported_by, target_id } = report;
          if (!reported_by || !target_id) return;
          const vicitimUser = await getDoc(doc(db, "users", reported_by));
          const targetUser = await getDoc(doc(db, "users", target_id));

          const updatedDoc = {
            ...report,
            reported_by: vicitimUser.data(),
            target_id: targetUser.data(),
          };
          reportListTemp[index] = updatedDoc;
        });
        await Promise.all(promises);
        return reportListTemp;
      };

      generateTempReports().then((reports) => dispatch(setReports(reports)));
    });

    // Getting reports

    // settingReports

    return () => {
      un1();
      un2();
      un4();
      un5();
      un6();
    };

    // getUsers();
    // getAdmins();
    // getCreators();
    // getCreatorsRequest();
  }, []);

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
