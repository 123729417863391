import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    data: [],
  },
  reducers: {
    setNotifications(state, action) {
      state.data = action.payload
    },
  },
});
export const { setNotifications } = notificationSlice.actions;
export default notificationSlice.reducer;
