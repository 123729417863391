import { filter, forEach } from "lodash";
import { Icon } from "@iconify/react";

import { sentenceCase } from "change-case";
import { useEffect, useRef, useState } from "react";
// import eyeIcon from "@iconify/icons-akar-icons/eye";
import trashIcon from "@iconify/icons-fe/trash";
import bxsEdit from "@iconify/icons-bx/bxs-edit";
//import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  // Avatar,
  // Button,
  IconButton,
  // Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  modalClasses,
  Button,
  Box,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// components
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import {
  TableTitle,
  TableHeader,
  // UserListToolbar,
  // UserMoreMenu,
} from "../components/_dashboard/user";
//
import USERLIST from "../_mocks_/reports";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { doc, getDoc, onSnapshot, updateDoc, setDoc, collection, getDocs, query } from "firebase/firestore";
import { db } from "src/database/db";
import { green, red } from "@mui/material/colors";
import { async } from "@firebase/util";
import { setReports } from "src/store";
import { updateItem } from "src/database/db";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserList() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  // const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { t } = useTranslation();
  // const reportsList = useSelector((state) => state.report.data);
  const[reportsList, setReportsList] = useState([])
  const [isChange,setIsChange] = useState([])
  const dispatch = useDispatch();

  useEffect(()=>{
    getDocs(collection(db, "reports")).then((snapshot) => {
      const reportsList = snapshot.docs.map((doc) => doc.data());

      const generateTempReports = async () => {
        const reportListTemp = [...reportsList];
        const promises = reportsList.map(async (report, index) => {
          const { reported_by, target_id } = report;
          if (!reported_by || !target_id) return;
          const vicitimUser = await getDoc(doc(db, "users", reported_by));
          const targetUser = await getDoc(doc(db, "users", target_id));

          const updatedDoc = {
            ...report,
            reported_by: vicitimUser.data(),
            target_id: targetUser.data(),
          };
          reportListTemp[index] = updatedDoc;
        });
        await Promise.all(promises);
        return reportListTemp;
      };

      generateTempReports().then((reports) => setReportsList(reports));
    });

  },[isChange])

  const filteredUsers = applySortFilter(
    reportsList,
    getComparator(order, orderBy)
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const TABLE_HEAD = [
    { id: "sno", label: "#", alignRight: false },
    { id: "reportedby", label: t("Reported_By"), alignRight: false },
    { id: "reportedagainst", label: t("Reported_Against"), alignRight: false },
    { id: "text", label: t("Text"), alignRight: false },
    // { id: "status", label: t("Status"), alignRight: false },
    { id: "action", label: t("Action"), alignRight: false },
  ];

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };



  // const handleClick = (event, name) => {
  // 	const selectedIndex = selected.indexOf(name);
  // 	let newSelected = [];
  // 	if (selectedIndex === -1) {
  // 		newSelected = newSelected.concat(selected, name);
  // 	} else if (selectedIndex === 0) {
  // 		newSelected = newSelected.concat(selected.slice(1));
  // 	} else if (selectedIndex === selected.length - 1) {
  // 		newSelected = newSelected.concat(selected.slice(0, -1));
  // 	} else if (selectedIndex > 0) {
  // 		newSelected = newSelected.concat(
  // 			selected.slice(0, selectedIndex),
  // 			selected.slice(selectedIndex + 1)
  // 		);
  // 	}
  // 	setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const isUserNotFound = filteredUsers.length === 0;

  const TableCellStyles = styled((props) => <TableCell {...props} />)(
    ({ theme }) => ({
      border: "1px solid #e3e6f0",
      fontSize: "12px",
    })
  );

  const [actionTaken, setactionTaken] = useState(false);

  useEffect(() => {
    setactionTaken(false);
  }, [reportsList]);

  const handleUnblock = async (target_id, reportId) => {
    const blockUser = collection(db, 'users');
    try {
      setactionTaken(true);
      // Generate a new document reference within the collection
      const userDocRef = doc(blockUser, target_id);
      await updateDoc(userDocRef, { isBlocked: false });
      console.log('User blocked successfully');
      setactionTaken(false);
      setIsChange(!isChange)
    } catch (error) {
      console.error('Error adding suspended user and report:', error);
      throw error;
    }
  };

  const handleBlock = async (target_id, reportId) => {
    const blockUser = collection(db, 'users');
    try {
      setactionTaken(true);
      // Generate a new document reference within the collection
      const userDocRef = doc(blockUser, target_id);
      await updateDoc(userDocRef, { isBlocked: true });
      console.log('User blocked successfully');
      setactionTaken(false);
      setIsChange(!isChange)
    } catch (error) {
      console.error('Error adding suspended user and report:', error);
      throw error;
    }
  };

  const handleRemove = async (target_id, reportId) => {
    try {
      setactionTaken(true);
      await updateDoc(doc(db, "users", target_id), {
        isBlocked: true,
      });
      await updateDoc(doc(db, "reports", reportId), {
        report_status: "REMOVED",
      });
      let currentReport;
      let tempReports = [];


      reportsList.forEach((report) => {
        if (report.uid === reportId) {
          currentReport = { ...report, report_status: "REMOVED" };
          tempReports.push(currentReport);
        } else {
          tempReports.push(report);
        }
      });
      setactionTaken(true);
      dispatch(setReports(tempReports));
    } catch (e) {
      setactionTaken(false);
    }
  };

  const handleperBan = async (target, uid) => {
    const suspendDevicesCollectionRef = collection(db, 'suspend_devices');
    try {
      const newDocRef = doc(suspendDevicesCollectionRef);

      await setDoc(newDocRef, { deviceid: target?.deviceId });

      handleBlock(target?.uid, uid)
      return newDocRef.id;
    } catch (error) {
      console.error('Error adding suspended device:', error);
      throw error;
    }
  }

  const [isSuspendClick, setIsSuspendClick] = useState('')
  const [selectedDate , setSelectedDate] =  useState('')

  return (
    <Page title="Reports | Reports List">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h3" sx={{ color: "info.main" }}>
            {t("Reports")}
          </Typography>
          {/* <Button
						variant="contained"
						component={RouterLink}
						to="#"
						startIcon={<Icon icon={plusFill} />}
					>
						New User
					</Button> */}
        </Stack>

        <Card sx={{ borderRadius: "5px" }}>
          <TableTitle title={t("List_of_Reports")} />
          {/* <UserListToolbar
						numSelected={selected.length}
						filterName={filterName}
						onFilterName={handleFilterByName}
					/> */}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, p: "18px" }}>
              <Table>
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={reportsList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {reportsList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        date_time,
                        description,
                        image,
                        name,
                        report_status,
                        report_type,
                        reported_by,
                        target_id,
                        uid,
                      } = row;
                      const isItemSelected =
                        selected.indexOf(reported_by?.displayName) !== -1;
                      return (
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
														<Checkbox
															checked={isItemSelected}
															onChange={(event) => handleClick(event, name)}
														/>
													</TableCell> */}
                          <TableCellStyles
                            // sx={{ border: "1px solid red" }}
                            align="left"
                          >
                            {index}
                          </TableCellStyles>
                          <TableCellStyles align="left">
                            {reported_by ? reported_by.displayName : "-"}
                            <div style={{ color: "#6D6968" }}>
                              {reported_by ? reported_by.email : "-"}
                            </div>
                          </TableCellStyles>

                          <TableCellStyles align="left">
                            {target_id ? target_id.displayName : "-"}
                            <div style={{ color: "#6D6968" }}>
                              {target_id ? target_id.email : "-"}
                            </div>
                          </TableCellStyles>
                          <TableCellStyles align="left">
                            {description}
                          </TableCellStyles>
                          {/* <TableCellStyles align="left">
                            <Label
                              variant="ghost"
                              color={
                                (status === "banned" && "error") || "success"
                              }
                            >
                              {sentenceCase(status)}
                            </Label>
                          </TableCellStyles> */}
                          <TableCellStyles align="left">
                            {/* <IconButton
                              component={RouterLink}
                              to="/dashboard/adduser"
                              aria-label="view"
                              size="small"
                              sx={{ color: "info.main" }}
                            >
                              <Icon icon={bxsEdit} />
                            </IconButton>
                            <IconButton
                              component={RouterLink}
                              to="/dashboard/viewuser"
                              aria-label="view"
                              size="small"
                              sx={{ color: "info.main" }}
                            >
                              <Icon icon={trashIcon} />
                            </IconButton>
                           */}
                            {!target_id?.isBlocked ? (
                              isSuspendClick !== target_id?.uid ?
                                <Box sx={{ display: "flex" }}>
                                  <Button
                                    variant="outlined"
                                    disableElevation
                                    sx={{
                                      borderColor: red[500],
                                      color: red[500],
                                    }}
                                    onClick = {() => setIsSuspendClick(target_id?.uid)}
                                  >
                                    Suspend
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    disableElevation
                                    sx={{
                                      mx: "1rem",
                                      borderColor: red[500],
                                      color: red[500],
                                    }}
                                    disabled={actionTaken}
                                    onClick={() => {
                                      handleBlock(target_id.uid);
                                    }}
                                  >
                                    {actionTaken ? <>Blocking..</> : <>Block</>}
                                  </Button>

                                  <Button
                                    variant="outlined"
                                    disableElevation
                                    sx={{
                                      borderColor: red[500],
                                      color: red[500],
                                    }}
                                    disabled={actionTaken}
                                    onClick={() => {
                                      handleperBan(target_id, uid)
                                      .then((docId) => {
                                        console.log('Suspended device added successfully with ID:', docId);
                                      })
                                      .catch((error) => {
                                        console.error('Error adding suspended device:', error);
                                      });
                                    }}
                                  >
                                    {actionTaken ? <>Blocking..</> : <>Per Ban</>}
                                  </Button>
                                </Box>
                              :
                              <Box sx={{ display: "flex" }}>
                                <TextField
                                  label=""
                                  type="date"
                                  value={selectedDate}
                                  onChange={(e) => setSelectedDate(e.target.value)}
                                  required
                                />
                                 <Button
                                  variant="outlined"
                                  disableElevation
                                  sx={{
                                    borderColor: red[500],
                                    color: red[500],
                                    ml: 2
                                  }}
                                  onClick = {() => setIsSuspendClick('')}
                                >
                                  Confirm
                                </Button>
                                <Button
                                  variant="outlined"
                                  disableElevation
                                  sx={{
                                    borderColor: green[500],
                                    color: green[500],
                                    ml: 2
                                  }}
                                  onClick = {() => setIsSuspendClick('')}
                                >
                                  Cancel
                                </Button>
                              </Box>
                            ) : target_id?.isBlocked ? (
                              <Box>
                                <Button
                                  variant="outlined"
                                  disableElevation
                                  disabled={actionTaken}
                                  onClick={() => {
                                    handleUnblock(target_id.uid, uid);
                                  }}
                                >
                                  {actionTaken ? (
                                    <>Unblocking..</>
                                  ) : (
                                    <>Unblock</>
                                  )}
                                </Button>
                              </Box>
                            ) : report_status === "REMOVED" ? (
                              <Box color={red[800]} fontWeight="bolder">
                                User Removed
                              </Box>
                            ) : (
                              <Box color={green[800]} fontWeight="bolder">
                                Reviewed
                              </Box>
                            )}
                          </TableCellStyles>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCellStyles colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCellStyles
                        align="center"
                        colSpan={6}
                        sx={{ py: 3 }}
                      >
                        {/* searchQuery={filterName} */}
                        {/* <SearchNotFound /> */}
                      </TableCellStyles>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
